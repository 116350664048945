import React from "react"
import { graphql, PageProps } from "gatsby"

import { useAnalytics } from "../../hooks"

import { pageType } from "../../types"
import {
  Header,
  Layout,
  SEO,
  SignOffStillLooking,
  Register,
  SectionsHandler,
} from "../../components"

type Props = PageProps & {
  data: pageType
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()

  const url = process.env.GATSBY_SITE_URL + "/register/"
  analytics.track(["User", "Page View"], { page: "/register/" })

  return (
    <Layout>
      <SEO
        title={data.contentfulPages.seo.title}
        description={data.contentfulPages.seo.description.description}
        image={data.contentfulPages.seo.socialImage?.file?.url}
        url={url}
      />
      <Header
        heading={data.contentfulPages.heading}
        headerIntroduction={data.contentfulPages.headerIntroduction}
        variant="register"
      />
      <Register />
      <SectionsHandler sections={data.contentfulPages.sections} />
      <SignOffStillLooking />
    </Layout>
  )
}

export const pageQuery = graphql`
  query getRegisterPageData {
    contentfulPages(page: { eq: "Register" }) {
      contentful_id
      heading
      headerIntroduction {
        raw
      }
      headerImage {
        gatsbyImageData
        title
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
      sections {
        ... on ContentfulPageSectionsCourseGroups {
          contentful_id
          name
          __typename
          courseGroups {
            ... on ContentfulCourseGroups {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                title
                gatsbyImageData
              }
              contentful_id
              slug
            }
            ... on ContentfulCourseTopics {
              name
              homepageDescription {
                homepageDescription
              }
              image {
                gatsbyImageData
                title
              }
              contentful_id
              slug
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsFaQs {
          __typename
          contentful_id
          heading
          faQs {
            heading
            content {
              raw
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsHowItWorks {
          __typename
          contentful_id
          heading
          steps {
            contentful_id
            description {
              description
            }
            heading
            icon {
              file {
                url
              }
              gatsbyImageData
            }
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsStats {
          __typename
          contentful_id
          stats {
            name
            description {
              description
            }
            number
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
      }
    }
  }
`
